import useCustomRouter from "@/hooks/useCustomRouter"
import React, { useEffect } from "react"
import { GA_TRACKING_ID, googleAnalytics } from "./gtag"
import { useAuth } from "../providers/authContext"
import { GoogleTagManager, GoogleAnalytics } from "@next/third-parties/google"

const { pageView, config, event } = googleAnalytics

const GtagManager = () => {
  const router = useCustomRouter()
  const { user } = useAuth()

  useEffect(() => {
    const url = typeof window !== "undefined" ? window.location.pathname + window.location.search : ""

    pageView(url)
  }, [router.asPath])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (user) {
        const user_id = user.uid
        if (user_id) {
          config({ user_id })
          event({ action: "login", label: `${user.uid}`, value: 1, category: "user" })
        }
        return
      }
      config({ user_id: "anonymous" })
    }, 150)

    return () => clearTimeout(timer)
  }, [user])

  return (
    <>
      <GoogleTagManager gtmId={GA_TRACKING_ID} />
      <GoogleAnalytics gaId={GA_TRACKING_ID} />
    </>
  )
}

export default GtagManager
