import { useIsChangingLocation } from "@/hooks"
import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"

const RouterLoadingTransition = () => {
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const isTransitioning = useIsChangingLocation()

  useEffect(() => {
    let timer: NodeJS.Timeout
    let cleanupTimeout: NodeJS.Timeout

    const startLoading = () => {
      setProgress(30)
      setIsLoading(true)
    }

    const finishLoading = () => {
      setProgress(100)
      cleanupTimeout = setTimeout(() => {
        setIsLoading(false)
        setProgress(0)
      }, 350)
    }

    timer = setTimeout(() => {
      if (isTransitioning) {
        startLoading()
      } else {
        finishLoading()
      }
    }, 150)

    return () => {
      clearTimeout(timer)
      if (cleanupTimeout) clearTimeout(cleanupTimeout)
    }
  }, [isTransitioning])

  if (!isLoading) return null

  return (
    <div className="fixed top-0 left-0 w-full h-0.5 z-[50]">
      <div className="relative w-full h-full">
        <motion.div
          className="absolute top-0 left-0 rounded-sm w-full h-full bg-purple-400"
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.35 }}
        />
      </div>
    </div>
  )
}

export default RouterLoadingTransition
