import { FC, PropsWithChildren } from "react"
import GtagManager from "@/lib/GtagManager"
import { AuthProvider } from "@/providers/authContext"
import { ProgressingProvider } from "@/providers/ProgressingProvider"
import { DialogProvider } from "@/providers/DialogProvider"
import { RoutedDialogProvider } from "@/providers/RoutedDialogProvider"
import { BrowserRouterProvider } from "@/providers/BrowserRouterProvider"
import { ClientHistoryStore } from "@/stores/ClientHistoryStore"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TooltipProvider } from "@radix-ui/react-tooltip"
import RouterLoadingTransition from "@/components/RouterLoadingTransition"
import { ExploreProvider } from "./ExploreProvider"
import { WorkspaceProvider } from "./WorkspaceProvider"

const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BrowserRouterProvider>
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <TooltipProvider>
            <ExploreProvider>
              <ProgressingProvider>
                <WorkspaceProvider>{children}</WorkspaceProvider>
                <DialogProvider />
                <RouterLoadingTransition />
              </ProgressingProvider>
            </ExploreProvider>
          </TooltipProvider>
          <ClientHistoryStore />
          <RoutedDialogProvider />
          <GtagManager />
        </DndProvider>
      </AuthProvider>
    </BrowserRouterProvider>
  )
}

export default AppProviders
